body#top{
	.slick-list{
		overflow: visible;
	}
	.slick-dots{
		bottom: 120px;
		left: auto;
		@include flex(wrap,center,space-between);
		li{

		}
		button:before{
			color: #014099;
			opacity: 0.5;
		}
		li.slick-active button:before{
			opacity: 1;
		}
	}
	@include media(sp){
		.slick-dots{
			bottom: 0px;
			left: auto;
			@include flex(wrap,center,space-between);
			li{

			}
			button:before{
				color: #014099;
				opacity: 0.5;
			}
			li.slick-active button:before{
				opacity: 1;
			}
		}
	}
	#key-block{
		position: relative;
		#lead-btn{
			width: 100%;
			overflow: hidden;
			position: absolute;
			bottom: -60px;
			left: 50%;
			-ms-transform: translate(-50%, 20%);
			-webkit-transform: translate(-50%, 20%);
			transform: translate(-50%, 20%);
			z-index: 100;
			background: #fff;
			padding: 0 20px;
			.slick-track{
				// @include flex(wrap,center,space-between);
			}
			.slick-list{
				width: 600px;
			}
			.lead-block-txt{
				width: 60%;
				padding: 0 10px 0 0;
				h2{
					color: #014099;
					font-weight: 500;
					font-size: 2.2rem;
					margin: 0 0 10px 0;
				}
				p{
					margin: 0;
					color: #18384c;
					font-size: 1.4rem;
				}
			}
			.lead-block-img{
				width: 40%;
				padding: 10px;
			}
			.lead-btn-more{
				position: absolute;
				right: 30px;
				bottom: 10px;
				font-size: 1.2rem;
				font-weight: 300;
				color: #014099;
			}
			a,
			a:link,
			a:visited,
			a:active{
				border: 2px solid #ececec;
				border-radius: 10px;
				width: 600px;
				@include flex(wrap,center,space-between);
				-ms-flex-align: center;
				align-items: center;
				padding: 20px 30px;
				margin: 0 30px;
				position: relative;
			}
			a:hover{
				@include hover;
			}
			.nolink{
				pointer-events: none;
			}
		}
		@include media(sp){
			#lead-btn{
				position: static;
				width: auto;
				margin: 30px 10px;
				padding: 0;
				-ms-transform: initial;
				-webkit-transform: initial;
				transform: initial;
				.slick-list{
					width: auto;
				}
				.lead-block-txt{
					width: 60%;
					h2{
						font-size: 2rem;
					}
					p{
						margin: 0;
						color: #18384c;
						font-size: 1.2rem;
					}
				}
				.lead-block-img{
					width: 40%;
				}
				.lead-btn-more{
				}
				a,
				a:link,
				a:visited,
				a:active{
					padding: 20px 20px;
					margin: 0 5px;
				}
				a:hover{
				}
			}
		}
	}
	#key{
		min-width: 1280px;
		padding: 0 0 160px 0;
		overflow: hidden;
		position: relative;
		.keyimg-box-wrap{
			max-width: 1280px;
			min-height: 700px;
			// max-height: 900px;
			height: 70vh;
			position: relative;
			margin: 0 auto;
		}

		@include media(sp){
			.keyimg-box-wrap{
				max-width: none;
			}
		}
		.keyimg-wrap{
			height: 100%;
			min-height: 720px;
			max-height: 720px;
			width: 1280px;
			@include center;
			margin: 0 auto;
		}
		@include media(sp){
			.keyimg-wrap{
				width: 100%;
			}
		}
		.slider{
			height: 70vh;
			min-height: 700px;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			margin: 0 0 0 0;
			.slider-ttl{
			}
			.right-txt,
			.left-txt{
				position: absolute;
				font-family: 'Asap',"Noto Sans JP";
				font-size: 1.5rem;
				padding: 4px 10px;
			}
			.left-txt{
				color: #fff;
				background: #014099;
				top: 0;
				border-bottom-right-radius: 6px;
			}
			.right-txt{
				color: #014099;
				background: #fff;
				bottom: 0;
				right: 0;
				border-top-left-radius: 6px;
			}
			@include media(sp){
				.slider-ttl{
				}
				.right-txt,
				.left-txt{
					font-size: 1rem;
					padding: 4px 5px;
				}
				.left-txt{
				}
				.right-txt{
					font-size: 1rem;
				}
			}
		}
		.slider-ttl{
			margin: 0;
			position: absolute;
			top: 50%;
			left: 30px;
			-ms-transform: translate(0, -30%);
			-webkit-transform: translate(0, -30%);
			transform: translate(0, -30%);
			opacity: 0;
			-webkit-transition: all 0.2s ease;
			-moz-transition: all 0.2s ease;
			-o-transition: all 0.2s ease;
			transition: all 0.2s ease;
		}
		@include media(sp){
			.slider{
				.slider-ttl{
				}
				.right-txt,
				.left-txt{
				}
				.left-txt{
				}
				.right-txt{
				}
			}
			.slider-ttl{
				margin: 0;
				position: absolute;
				top: 50%;
				left: 20px;
				width: 100%;
			}
		}
		.keyimg01,
		.keyimg02,
		.keyimg03,
		.keyimg04,
		.keyimg05,
		.keyimg06,
		.keyimg07,
		.key-blurimg01,
		.key-blurimg02{
			overflow: hidden;
			position: absolute;
			-ms-transform:  scale(0);
			-webkit-transform:  scale(0);
			transform:  scale(0);
			-webkit-transition: all 1s ease;
			-moz-transition: all 1s ease;
			-o-transition: all 1s ease;
			transition: all 1s ease;
			z-index: 10;
		}
		.key-blurimg01,
		.key-blurimg02{
			-ms-filter: blur(6px);
			filter: blur(6px);
			z-index: 3;
		}
		.slider-ceo{
			background: #caefff;
			.key-ceo-logo{
				position: absolute;
				left: 20px;
				top: 40px;
			}
			#ceo-bg{
				@include center;
				z-index: 0;
				opacity: 0.4;
				width: 100%;
				height: 100%;
			}
			.slider-ttl{
				z-index: 10;
				top: 10%;
				left: 10px;
				-ms-transform: translate(0, 0) scale(10);
				-webkit-transform: translate(0, 0) scale(10);
				transform: translate(0, 0) scale(10);
				img{
					// width: 900px;
					height: 40vh;
					min-height: 400px;
				}
			}
			.ceo-btn{
				position: absolute;
				z-index: 20;
				bottom: 0;
				left: 12%;
				@include flex;
				-ms-flex-align: center;
				align-items: center;
				opacity: 0;
				-webkit-transition: all 1s ease;
				-moz-transition: all 1s ease;
				-o-transition: all 1s ease;
				transition: all 1s ease;
				#ceo-img{
					img{
						width: 250px;
					}
				}
				#ceo-detail{
					text-align: center;
					padding: 0 0 0 34px;
					.scene-more-icon{
						display: block;
						height: 1px;
						width: 20px;
						background: #d2a100;
						position: absolute;
						right: 15px;
						top: 50%;
						margin-top: -1px;
					}
					.scene-more-icon:before{
						content: "";
						width: 7px;
						height: 7px;
						display: block;
						background: #d2a100;
						border-radius: 5px;
						position: absolute;
						right: -7px;
						top: 50%;
						margin-top: -3px;
						@include trans;
					}
					a,
					a:link,
					a:visited,
					a:active{
						margin: 30px auto 0 auto;
						text-align: center;
						width: 240px;
						padding: 15px 10px;
						font-size: 1.4rem;
						position: relative;
						display: block;
						background: #014099;
						color: #fff;
					}
					a:hover{
						.scene-more-icon:before{
							right: 15px;
						}
					}
				}
			}
			.keyimg01{
				top: 97px;
				right: -31px;
			}
			.keyimg02{
				bottom: 44px;
				right: 251px;
				top: auto;
			}
			.keyimg03{
				top: 250px;
				right: -31px;
			}
			.keyimg04{
				top: auto;
				right: -190px;
				bottom: 220px;
			}
			.keyimg05{
				top: auto;
				right: 110px;
				bottom: -90px;
			}
			.keyimg06{
				top: 545px;
				right: 220px;
			}
			.keyimg07{
				top: 250px;
				// top: 50%;
				right: 70px;
			}
			.key-blurimg01{
				top: auto;
				right: 156px;
				bottom: 190px;
			}
			.key-blurimg02{
				top: auto;
				right: -110px;
				bottom: -20px;
			}
		}


		.slider.slider-ceo.slick-active{
			.slider-ttl{
				opacity: 0;
				-ms-transform:  scale(10);
				-webkit-transform:  scale(10);
				transform:  scale(10);
			}
			.keyimg01,
			.keyimg02,
			.keyimg03,
			.keyimg04,
			.keyimg05,
			.keyimg06,
			.keyimg07,
			.key-blurimg01,
			.key-blurimg02{
				position: absolute;
				// transform: scale(1);
				// -ms-transform:  scale(1) translate(0, -50%);
				// -webkit-transform:  scale(1) translate(0, -50%);
				// transform:  scale(1) translate(0, -50%);
				-ms-transform:  scale(0);
				-webkit-transform:  scale(0);
				transform:  scale(0);
			}
		}
		.slider.slick-active.slider01-active{

			.ceo-btn{
				opacity: 1;
			}
			.slider-ttl{
				opacity: 1;
				-ms-transform:  scale(1);
				-webkit-transform:  scale(1);
				transform:  scale(1);
			}
			.keyimg01,
			.keyimg02,
			.keyimg03,
			.keyimg04,
			.keyimg05,
			.keyimg06,
			.keyimg07,
			.key-blurimg01,
			.key-blurimg02{
				position: absolute;
				// transform: scale(1);
				// -ms-transform:  scale(1) translate(0, -50%);
				// -webkit-transform:  scale(1) translate(0, -50%);
				// transform:  scale(1) translate(0, -50%);
				-ms-transform:  scale(1);
				-webkit-transform:  scale(1);
				transform:  scale(1);
			}
		}

		@include media(sp){
			.slider-ceo{
				background: #caefff;
				min-height: 500px;
				.keyimg-box-wrap{
					min-height: 500px;
					.keyimg-wrap{
						min-height: 500px;
					}
				}
				.key-ceo-logo{
					position: absolute;
					left: 10px;
					top: 20px;
				}
				#ceo-bg{
				}
				.slider-ttl{
					top: 50%;
					left: 50%;
					-ms-transform: translate(-50%, -50%) scale(10);;
					-webkit-transform: translate(-50%, -50%) scale(10);;
					transform: translate(-50%, -50%) scale(10);;
					img{
						width: 100%;
						height: auto;
						min-height: auto;
					}
				}
				.ceo-btn{
					left: 0;
					width: 100%;
					padding: 0 10px;
					-ms-flex-align: end;
					align-items: end;
					#ceo-img{
						width: 30%;
						img{
							width: auto;
						}
					}
					#ceo-detail{
						width: 70%;
						padding: 0 0 10px 10px;
						.scene-more-icon{
						}
						.scene-more-icon:before{
						}
						a,
						a:link,
						a:visited,
						a:active{
							margin: 10px 10px 0 10px;
						}
						a:hover{
							.scene-more-icon:before{
								right: 15px;
							}
						}
					}
				}


				.keyimg01{
					top: 30px;
					right: 110px;
					width: 150px;
					z-index: 0;
				}
				.keyimg02{
					top: 70px;
					bottom: auto;
					right: auto;
					left: -40px;
					width: 95px;
					z-index: 0;
				}
				.keyimg03{
					top: 10px;
					right: 10px;
					width: 120px;
				}
				.keyimg04{
					top: -17px;
					right: 140px;
					width: 80px;
					bottom: auto;
					z-index: 0;
				}
				.keyimg05{
					top: 70px;
					right: auto;
					bottom: auto;
					left: 60px;
					width: 80px;
				}
				.keyimg06{
					top: auto;
					right: auto;
					bottom: 123px;
					left: 66px;
					width: 125px;
				}
				.key-blurimg01{
					top: auto;
					right: -50px;
					bottom: 180px;
					left: auto;
					width: 90px;
				}
				.key-blurimg02{
					top: 80px;
					bottom: auto;
					right: auto;
					width: 110px;
					left: 200px;
				}
			}
		}

		@include media(sp){
			.slider.slider-ceo.slick-active{
				.slider-ttl{
					opacity: 0;
					-ms-transform:  scale(10);
					-webkit-transform:  scale(10);
					transform:  scale(10);
				}
				.keyimg01,
				.keyimg02,
				.keyimg03,
				.keyimg04,
				.keyimg05,
				.keyimg06,
				.keyimg07,
				.key-blurimg01,
				.key-blurimg02{
					position: absolute;
					// transform: scale(1);
					// -ms-transform:  scale(1) translate(0, -50%);
					// -webkit-transform:  scale(1) translate(0, -50%);
					// transform:  scale(1) translate(0, -50%);
					-ms-transform:  scale(0);
					-webkit-transform:  scale(0);
					transform:  scale(0);
				}
			}
			.slider.slick-active.slider01-active{

				.ceo-btn{
					opacity: 1;
				}
				.slider-ttl{
					position: absolute;
					top: 50%;
					left: 50%;
					opacity: 1;
					-ms-transform:   translate(-50%, -50%) scale(1);
					-webkit-transform:   translate(-50%, -50%) scale(1);
					transform:   translate(-50%, -50%) scale(1);
				}
				.keyimg01,
				.keyimg02,
				.keyimg03,
				.keyimg04,
				.keyimg05,
				.keyimg06,
				.keyimg07,
				.key-blurimg01,
				.key-blurimg02{
					position: absolute;
					// transform: scale(1);
					// -ms-transform:  scale(1) translate(0, -50%);
					// -webkit-transform:  scale(1) translate(0, -50%);
					// transform:  scale(1) translate(0, -50%);
					-ms-transform:  scale(1);
					-webkit-transform:  scale(1);
					transform:  scale(1);
				}
			}
		}
		.slider.slider03{
			background: #bcebff;
			min-height: 700px;
			.slider-ttl{
				z-index: 15;
				img{
					width: 650px;
				}
			}
			.keyimg-box-wrap{
				// max-height: 800px;
				min-height: 700px;
				max-width: none;
				position: absolute;
				top: 50%;
				left: 50%;
				-ms-transform: translate(-50%, -50%);
				-webkit-transform: translate(-50%, -50%);
				transform: translate(-50%, -50%);
				width: 100%;
			}
			.keyimg-wrap{
				min-height: auto;
				max-height: none;
				margin: 0 auto;
				width: 100%;
				.slider-ttl{
					margin: 0;
					position: absolute;
					top: 60px;
					left: 50%;
					-ms-transform: translate(-50%, 0);
					-webkit-transform: translate(-50%, 0);
					transform: translate(-50%, 0);
				}
				// #hidentalworld-block{
				// 	padding-top: 50px;
				// 	min-height: 700px;
				// 	max-width: 2200px;
				// 	@include center;
				// 	width: 100%;
				// }
				// .keyimg01{
				// 	width: 26%;
				// 	top: 30%;
				// 	right: auto;
				// 	left: 19%;
				// 	z-index: 30;
				// 	-webkit-transition: all .1s ease 0s;
				// 	-moz-transition: all .1s ease 0s;
				// 	-o-transition: all .1s ease 0s;
				// 	transition: all .1s ease 0s;
				// 	img{
				// 		width: 100%;
				// 	}
				// }
				// .keyimg02{
				// 	width: 13%;
				// 	left: 48%;
				// 	top: 30%;
				// 	margin: 0 0 0 -4%;
				// 	-webkit-transition: all .7s ease 0s;
				// 	-moz-transition: all .7s ease 0s;
				// 	-o-transition: all .7s ease 0s;
				// 	transition: all .7s ease 0s;

				// 	// @include center;
				// 	right: auto;
				// 	z-index: 50;
				// 	img{
				// 		width: 100%;
				// 	}
				// }
				// .keyimg03{
				// 	width: 24%;
				// 	top: 25%;
				// 	left: auto;
				// 	right: 20%;
				// 	z-index: 30;
				// 	-webkit-transition: all 1.3s ease 0s;
				// 	-moz-transition: all 1.3s ease 0s;
				// 	-o-transition: all 1.3s ease 0s;
				// 	transition: all 1.3s ease 0s;

				// 	img{
				// 		width: 100%;
				// 	}
					
				// }

				#hidentalworld-block{
					padding-top: 270px;
					padding-bottom: 80px;
					height: 100%;
					@include flex(wrap,center,space-between);
					@include center;
					width: 100%;
					// height: 100%;
				}
				.keyimg01{
					background-repeat: no-repeat;
					background-position: top right;
					background-size: contain;
					position: static;
					width: 45%;
					height: 100%;
					padding: 30px;
					-webkit-transition: all .1s ease 0s;
					-moz-transition: all .1s ease 0s;
					-o-transition: all .1s ease 0s;
					transition: all .1s ease 0s;
					img{
						width: 100%;
						// height: 53vh;
						// width: auto;
						// max-height: 500px;
					}
				}
				.keyimg02{
					background-repeat: no-repeat;
					background-position: center center;
					background-size: contain;
					position: static;
					width: 15%;
					height: 100%;
					padding: 5px;
					-webkit-transition: all .7s ease 0s;
					-moz-transition: all .7s ease 0s;
					-o-transition: all .7s ease 0s;
					transition: all .7s ease 0s;

					// @include center;
					right: auto;
					z-index: 50;
					img{
						width: 100%;
						// height: 40vh;
						// width: auto;
						// max-height: 460px;
					}
				}
				.keyimg03{
					background-repeat: no-repeat;
					background-position: top left;
					background-size: contain;
					position: static;
					width: 40%;
					height: 100%;
					padding: 0 30px 30px 30px;
					-webkit-transition: all 1.3s ease 0s;
					-moz-transition: all 1.3s ease 0s;
					-o-transition: all 1.3s ease 0s;
					transition: all 1.3s ease 0s;

					img{
						width: 100%;
						// height: 39vh;
						// width: auto;
						// max-height: 400px;
					}
					
				}
			}
		}
		// .slider03.slick-active{
		// 	.keyimg-wrap{
		// 		.keyimg01{
		// 			-ms-transform:scale(1);
		// 			-webkit-transform:scale(1);
		// 			transform:scale(1);
		// 		}
		// 		.keyimg02{
		// 			-ms-transform:scale(1);
		// 			-webkit-transform:scale(1);
		// 			transform:scale(1);
		// 		}
		// 		.keyimg03{
		// 			-ms-transform:scale(1);
		// 			-webkit-transform:scale(1);
		// 			transform:scale(1);
		// 		}
		// 	}
		// }
		@include media(sp){
			.slider.slider03{
				min-height: 500px;
				.keyimg-wrap{
					.slider-ttl{
						padding: 0 10px;
						top: 30px;
						img{
							margin: 0 auto;
							width: 320px;
						}
					}
				}
				.keyimg-box-wrap{
					min-height: 500px;
				}
				.keyimg-wrap{
					.slider-img{
						img{
							height: auto;
							width: 100%;
						}
					}
					#hidentalworld-block{
						display: block;
						@include center;
						width: 100%;
						min-height: 500px;
						margin: 0 auto;
						padding: 50px 0 0 0;
					}
					.keyimg01{
						background: transparent !important;
						height: auto;
						padding: 0;
						position: absolute;
						width: auto;
						top: 20%;
						left: 50%;
						right: auto;
						margin-left: -160px;
						// margin: 0 auto;
						z-index: 30;
						text-align: center;
						// padding: 0 0 0 10px;
						-webkit-transition: all 1.3s ease 0s;
						-moz-transition: all 1.3s ease 0s;
						-o-transition: all 1.3s ease 0s;
						transition: all 1.3s ease 0s;
						img{
							// width: 320px;
							width: 80%;
							max-width: 320px;
							height: auto;
							// max-height: 500px;
							margin: 0 auto
						}
					}
					.keyimg02{
						background: transparent !important;
						height: auto;
						padding: 0;
						position: absolute;
						width: auto;
						top: 50%;
						left: 50%;
						right: auto;
						z-index: 50;
						margin: 0 0 0 -160px;
						text-align: center;
						padding: 0 10px;
						img{
							// width: 300px;
							width: 70%;
							max-width: 300px;
							height: auto;
							// max-height: 460px;
							margin: 0 auto
						}
					}
					.keyimg03{
						background: transparent !important;
						height: auto;
						padding: 0;
						position: absolute;
						width: auto;
						top: auto;
						bottom: 30px;
						left: 50%;
						right: auto;
						margin-left: -160px;
						z-index: 30;
						text-align: center;
						padding: 0 0 0 0;
						-webkit-transition: all .1s ease 0s;
						-moz-transition: all .1s ease 0s;
						-o-transition: all .1s ease 0s;
						transition: all .1s ease 0s;
						img{
							// width: 320px;
							width: 80%;
							max-width: 320px;
							height: auto;
							// max-height: 400px;
							margin: 0 auto;
						}
						
					}
				}
			}
		}
		.slider01{
			.slider-ttl{
				z-index: 10;
				-webkit-filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				-moz-filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				img{
					width: 800px;
				}
			}
			.slider-ttl.sp-ttl{
				.pc-keyttl{
					display: block;
				}
				.sp-keyttl{
					display: none;
				}
			}
			.key-ceo-logo{
				position: absolute;
				bottom: 50px;
				left: 50%;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
			}
			.keyimg01{
				top: -40px;
				right: 220px;
			}
			.keyimg02{
				top: 104px;
				right: 70px;
			}
			.keyimg03{
				top: 250px;
				right: 70px;
				
			}
			.keyimg04{
				top: 250px;
				right: -80px;
			}
			.keyimg05{
				top: 395px;
				right: 360px;
			}
			.keyimg06{
				top: 545px;
				right: 220px;
			}
			.keyimg07{
				top: 250px;
				// top: 50%;
				right: 70px;
			}
			.key-blurimg01{
				top: 220px;
				right: 266px;
			}
			.key-blurimg02{
				top: 400px;
				right: -10px;
			}
		}
		@include media(sp){
			.slider01{
				min-height: 500px;
				.keyimg-box-wrap{
					min-height: 500px;
				}
				.slider-ttl{
					left: auto;
					padding: 0 10px;
					img{
						-webkit-filter: drop-shadow(1px 1px 3px #d8d8d8);
						-moz-filter: drop-shadow(1px 1px 3px #d8d8d8);
						filter: drop-shadow(1px 1px 3px #d8d8d8);
						width: 100%;
					}
				}
				.slider-ttl.sp-ttl{
					width: auto;
					top: 6%;
					right: 50px;
					-ms-transform: translate(0, 0);
					-webkit-transform: translate(0, 0);
					transform: translate(0, 0%);
					img.sp-keyttl{
						width: auto;
						height: 60vh;
					}
				}
				.slider-ttl.sp-ttl{
					.pc-keyttl{
						display: none;
					}
					.sp-keyttl{
						display: block;
					}
				}
				.key-ceo-logo{
					position: absolute;
					bottom: 20px;
					z-index: 20;
					img{
						width: 100px;
					}
				}

				.keyimg01{
					top: 80px;
					right: auto;
					width: 150px;
					left: -30px;
				}
				.keyimg02{
					top: 15px;
					right: auto;
					left: 10px;
					width: 135px;
				}
				.keyimg03{
					top: 190px;
					right: auto;
					left: 80px;
					width: 90px;
				}
				.keyimg04{
					top: 233px;
					right: auto;
					width: 80px;
					left: -10px;
				}
				.keyimg05{
					top: auto;
					right: auto;
					bottom: 80px;
					left: 10px;
					width: 100px;
				}
				.keyimg06{
					top: auto;
					right: auto;
					bottom: 123px;
					left: 66px;
					width: 125px;
				}
				.key-blurimg01{
					top: auto;
					right: auto;
					bottom: 185px;
					left: 61px;
					width: 90px;
				}
				.key-blurimg02{
					top: 79px;
					right: auto;
					width: 110px;
					left: 140px;
				}
			}
		}


		.slider02{
			.key-ceo-logo{
				position: absolute;
				bottom: 50px;
				left: 50%;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
			}
			.slider-ttl{
				-webkit-filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				-moz-filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				img{
					width: 800px;
				}
			}
			.slider-ttl.sp-ttl{
				.pc-keyttl{
					display: block;
				}
				.sp-keyttl{
					display: none;
				}
			}
			.keyimg01{
				top: -30px;
				right: 10px;
			}
			.keyimg02{
				top: 114px;
				right: 300px;
			}
			.keyimg03{
				top: 260px;
				right: 150px;
			}
			.keyimg04{
				top: 276px;
				right: -6px;
			}
			.keyimg05{
				bottom: 30px;
				right: 295px;
			}
			.keyimg06{
				bottom: 10px;
				right: -142px;
				z-index: 1;
			}
			.keyimg07{
				bottom: 30px;
				right: 150px;
			}
			.key-blurimg01{
				top: 190px;
				right: -94px;
			}
			.key-blurimg02{
				top: 410px;
				right: 320px;
			}
		}


		@include media(sp){
			.slider02{
				min-height: 500px;
				.keyimg-box-wrap{
					min-height: 500px;
				}
				.slider-ttl{
					left: auto;
					padding: 0 10px;
					z-index: 10;
					img{
						width: 100%;
						-webkit-filter: drop-shadow(1px 1px 3px #d8d8d8);
						-moz-filter: drop-shadow(1px 1px 3px #d8d8d8);
						filter: drop-shadow(1px 1px 3px #d8d8d8);
					}
				}
				.slider-ttl.sp-ttl{
					width: auto;
					top: 5%;
					right: 50px;
					-ms-transform: translate(0, 0);
					-webkit-transform: translate(0, 0);
					transform: translate(0, 0%);
					img.sp-keyttl{
						width: auto;
						height: 65vh;
					}
				}
				.slider-ttl.sp-ttl{
					.pc-keyttl{
						display: none;
					}
					.sp-keyttl{
						display: block;
					}
				}
				.key-ceo-logo{
					position: absolute;
					bottom: 20px;
					z-index: 20;
					img{
						width: 100px;
					}
				}
				.keyimg01{
					z-index: 0;
					top: -30px;
					right: -20px;
					width: 180px;
				}
				.keyimg02{
					z-index: 0;
					top: 210px;
					right: auto;
					left: 0px;
					width: 100px;
				}
				.keyimg03{
					z-index: 0;
					top: auto;
					right: auto;
					width: 140px;
					left: 30px;
					bottom: 180px;
				}
				.keyimg04{
					z-index: 0;
					top: 10px;
					right: auto;
					left: -50px;
				}
				.keyimg05{
					z-index: 0;
					top: 120px;
					right: auto;
					left: -70px;
					bottom: auto;
				}
				.keyimg06{
					z-index: 0;
					bottom: 30px;
					right: -78px;
					top: auto;
					width: 200px;
				}
				.keyimg07{
					z-index: 0;
					top: auto;
					right: auto;
					left: -90px;
					bottom: 200px;
				}
				.key-blurimg01{
					z-index: 0;
					top: 190px;
					right: -94px;
				}
				.key-blurimg02{
					z-index: 0;
					top: auto;
					right: auto;
					left: -130px;
					bottom: 30px;
				}
			}
		}


		.slider04{
			.key-ceo-logo{
				position: absolute;
				bottom: 50px;
				left: 50%;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
			}
			.slider-ttl{
				z-index: 10;
				-webkit-filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				-moz-filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				img{
					width: 800px;
				}
			}
			.slider-ttl.sp-ttl{
				.pc-keyttl{
					display: block;
				}
				.sp-keyttl{
					display: none;
				}
			}
			.keyimg01{
				top: 60px;
				right: 10px;
			}
			.keyimg02{
				top: 164px;
				right: 260px;
			}
			.keyimg03{
				top: 210px;
				right: 10px;
			}
			.keyimg04{
				bottom: 230px;
				right: 310px;
			}
			.keyimg05{
				top: 375px;
				right: -120px;
				z-index: 1;
			}
			.keyimg06{
				bottom: 40px;
				right: 180px;
			}
			.keyimg07{
				top: 580px;
				right: 220px;
			}
			.key-blurimg01{
				top: 190px;
				right: -94px;
			}
			.key-blurimg02{
				top: 410px;
				right: 320px;
			}
		}
		@include media(sp){
			.slider04{
				min-height: 500px;
				.keyimg-box-wrap{
					min-height: 500px;
				}
				.keyimg-box-wrap{
					min-height: 500px;
				}
				.slider-ttl{
					left: auto;
					padding: 0 10px;
					z-index: 10;
					img{
						width: 100%;
						-webkit-filter: drop-shadow(1px 1px 3px #d8d8d8);
						-moz-filter: drop-shadow(1px 1px 3px #d8d8d8);
						filter: drop-shadow(1px 1px 3px #d8d8d8);
					}
				}
				.slider-ttl.sp-ttl{
					width: auto;
					top: 5%;
					right: 50px;
					-ms-transform: translate(0, 0);
					-webkit-transform: translate(0, 0);
					transform: translate(0, 0%);
					img.sp-keyttl{
						width: auto;
						height: 60vh;
					}
				}
				.slider-ttl.sp-ttl{
					.pc-keyttl{
						display: none;
					}
					.sp-keyttl{
						display: block;
					}
				}
				.key-ceo-logo{
					position: absolute;
					bottom: 20px;
					z-index: 20;
					img{
						width: 100px;
					}
				}
				.keyimg01{
					z-index: 1;
					top: 10px;
					right: auto;
					left: 10px;
					width: 200px;
				}
				.keyimg02{
					z-index: 0;
					top: 120px;
					right: auto;
					left: -20px;
					width: 150px;
				}
				.keyimg03{
					z-index: 1;
					top: auto;
					right: auto;
					left: -20px;
					bottom: 30px;
					width: 150px;
				}
				.keyimg04{
					z-index: 0;
					top: 0;
					right: -30px;
					width: 150px;
					left: auto;
					bottom: auto;
				}
				.keyimg05{
					z-index: 0;
					top: auto;
					right: -50px;
					width: 150px;
					left: auto;
					bottom: 110px;
				}
				.keyimg06{
					z-index: 0;
					top: auto;
					right: auto;
					left: 30px;
					bottom: 150px;
				}
				.keyimg07{
					z-index: 0;
					top: 580px;
					right: 220px;
				}
				.key-blurimg01{
					top: 190px;
					right: -94px;
				}
				.key-blurimg02{
					top: 410px;
					right: 320px;
				}
			}
		}


		.slider05{
			.key-ceo-logo{
				position: absolute;
				bottom: 50px;
				left: 50%;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
			}
			.slider-ttl{
				-ms-transform: translate(0, -70%);
				-webkit-transform: translate(0, -70%);
				transform: translate(0, -70%);
				-webkit-filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				-moz-filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				filter: drop-shadow(-2px -2px 4px #fff) drop-shadow(2px 2px 4px #fff) drop-shadow(-1px -1px 4px #fff) drop-shadow(1px 1px 4px #fff);
				z-index: 50;
				img{
					width: 700px;
				}
			}
			.slider-ttl.sp-ttl{
				.pc-keyttl{
					display: block;
				}
				.sp-keyttl{
					display: none;
				}
			}
			.keyimg01{
				top: 0px;
				right: 65px;
				-webkit-transition: all .5s ease;
				-moz-transition: all .5s ease;
				-o-transition: all .5s ease;
				transition: all .5s ease;
			}
			.keyimg02{
				top: 143px;
				right: 356px;
				-webkit-transition: all .3s ease;
				-moz-transition: all .3s ease;
				-o-transition: all .3s ease;
				transition: all .3s ease;
			}
			.keyimg03{
				top: 287px;
				right: 211px;
				-webkit-transition: all 1s ease;
				-moz-transition: all 1s ease;
				-o-transition: all 1s ease;
				transition: all 1s ease;
				
			}
			.keyimg04{
				top: 290px;
				right: 70px;
				-webkit-transition: all .8s ease;
				-moz-transition: all .8s ease;
				-o-transition: all .8s ease;
				transition: all .8s ease;
			}
			.keyimg05{
				top: 434px;
				right: 360px;
				-webkit-transition: all 1.3s ease;
				-moz-transition: all 1.3s ease;
				-o-transition: all 1.3s ease;
				transition: all 1.3s ease;
			}
			.keyimg06{
				top: 433px;
				right: 213px;
				-webkit-transition: all 1.1s ease;
				-moz-transition: all 1.1s ease;
				-o-transition: all 1.1s ease;
				transition: all 1.1s ease;
			}
			.keyimg07{
				top: 433px;
				right: -78px;
				-webkit-transition: all 1.3s ease;
				-moz-transition: all 1.3s ease;
				-o-transition: all 1.3s ease;
				transition: all 1.3s ease;
			}
			.key-blurimg01{
				top: 140px;
				right: -34px;
			}
			.key-blurimg02{
				top: 310px;
				right: 390px;
			}
		}

		@include media(sp){
			.slider05{
				min-height: 500px;
				.keyimg-box-wrap{
					min-height: 500px;
				}
				.slider-ttl{
					left: auto;
					padding: 0 10px;
					z-index: 10;
					-ms-transform: translate(0, -50%);
					-webkit-transform: translate(0, -50%);
					transform: translate(0, -50%);
					img{
						width: 100%;
						-webkit-filter: drop-shadow(1px 1px 3px #d8d8d8);
						-moz-filter: drop-shadow(1px 1px 3px #d8d8d8);
						filter: drop-shadow(1px 1px 3px #d8d8d8);
					}
				}
				.slider-ttl.sp-ttl{
					width: auto;
					top: 8%;
					right: 50px;
					-ms-transform: translate(0, 0);
					-webkit-transform: translate(0, 0);
					transform: translate(0, 0%);
					img.sp-keyttl{
						width: auto;
						height: 50vh;
					}
				}
				.slider-ttl.sp-ttl{
					.pc-keyttl{
						display: none;
					}
					.sp-keyttl{
						display: block;
					}
				}
				.key-ceo-logo{
					position: absolute;
					bottom: 20px;
					z-index: 20;
					img{
						width: 100px;
					}
				}
				.keyimg01{
					z-index: 3;
					top: 0px;
					right: -120px;
					width: 200px;
				}
				.keyimg02{
					z-index: 1;
					top: 10px;
					right: auto;
					left: -30px;
					width: 150px;
				}
				.keyimg03{
					z-index: 2;
					top: 140px;
					right: auto;
					left: -30px;
					width: 150px;
					
				}
				.keyimg04{
					z-index: 2;
					top: auto;
					right: auto;
					left: -30px;
					bottom: 30px;
				}
				.keyimg05{
					z-index: 1;
					top: auto;
					right: auto;
					bottom: 110px;
					left: -70px;
				}
				.keyimg06{
					z-index: 1;
					top: auto;
					right: -30px;
					bottom: -10px;
				}
				.keyimg07{
					z-index: 1;
					top: auto;
					right: -90px;
					bottom: -30px;
					width: 200px;
				}
				.key-blurimg01{
					z-index: 1;
					top: 70px;
					right: -100px;
				}
				.key-blurimg02{
					z-index: 0;
					top: 40px;
					right: auto;
					left: -90px;
				}
			}
		}

		.basicalider{
			.keyimg-box-wrap{
				padding: 20px;
			}
			img{
				@include center;
			}
			.long-width{
				img{
					width: 100%;
				}
			}
			.long-height{
				img{
					height: 90%;
				}
			}
		}
		@include media(sp){
			.basicalider{
				min-height: 500px;
				.keyimg-box-wrap{
					min-height: 500px;
					padding: 0;
				}
				img{
					@include center;
				}
				.long-width{
					img{
						width: 95%;
					}
				}
				.long-height{
					img{
						width: 95%;
						height: auto;
					}
				}
			}
		}

		.slider-img{
			text-align: center;
			img{
				text-align: center;
				height: 70vh;
				max-height: 586px;
				margin: 0 auto;
			}
		}
		.slider.slick-active{
			.slider-ttl{
				opacity: 1;
			}
			.keyimg01,
			.keyimg02,
			.keyimg03,
			.keyimg04,
			.keyimg05,
			.keyimg06,
			.keyimg07,
			.key-blurimg01,
			.key-blurimg02{
				position: absolute;
				// transform: scale(1);
				// -ms-transform:  scale(1) translate(0, -50%);
				// -webkit-transform:  scale(1) translate(0, -50%);
				// transform:  scale(1) translate(0, -50%);
				-ms-transform:  scale(1);
				-webkit-transform:  scale(1);
				transform:  scale(1);
			}
		}
	}

	@include media(sp){
		#key{
			padding-bottom: 8%;
			min-width: auto;
			width: auto;
		}
	}
	#main-copy{
		padding: 100px 0 110px 0;
		width: 800px;
		margin: 0 auto;
		.message{
			text-align: center;
			margin: 0 0 35px 0;
		}
		.catch{
			margin: 0 0 50px 0;
			text-align: center;
		}
		.main-copy-txt{
			color: #18384c;
			margin: 0;
			line-height: 29px;
			text-align: center;
			strong{
				font-size: 2.6rem;
				font-weight:700;
				line-height: 45px;
			}
		}
		.main-copy-detail-txt{
			color: #18384c;
			margin: 50px 0 0 0;
			text-align: center;
			line-height: 45px;
			font-size: 2rem;
			font-weight: 500;
			.main-copy-intro{
				font-size: 2.6rem;
				font-weight:700;
			}
			.main-copy-middle{
				font-size: 2.5rem;
			}
		}
		.dx-ttl{
			text-align: center;
			margin: 40px 0 0 0;
		}
	}
	@include media(sp){
		#main-copy{
		padding: 50px 20px 110px 20px;
			width: auto;
		}
	}
}


@media (orientation: landscape){
	body#top.mobile-view{
		#key{
			.slider.slick-active.slider01-active .slider-ttl{
				width: 400px;
				left: 50%;
				top: 40%;
			}
			.slider-ceo .slider-ttl{
				img{
					margin: 0 auto;
					height: auto;
					min-height: auto;
				}
			}
			.slider01{
				.slider-ttl{
					z-index: 10;
					img{
						-webkit-filter: drop-shadow(1px 1px 3px #727272);
						-moz-filter: drop-shadow(1px 1px 3px #727272);
						filter: drop-shadow(1px 1px 3px #727272);
						width: 800px;
					}
				}
				.slider-ttl.sp-ttl{
					margin: 0;
					position: absolute;
					top: 50%;
					left: 30px;
					-ms-transform: translate(0, -30%);
					-webkit-transform: translate(0, -30%);
					transform: translate(0, -30%);
					-webkit-transition: all 0.2s ease;
					-moz-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					transition: all 0.2s ease;
					img.sp-keyttl{
						display: none;
					}
					img.pc-keyttl{
						display: block;
					}
				}
				.keyimg01{
					top: -40px;
					right: 220px;
					left: auto;
					bottom: auto;
				}
				.keyimg02{
					top: 104px;
					right: 70px;
					left: auto;
					bottom: auto;
				}
				.keyimg03{
					top: 250px;
					right: 70px;
					left: auto;
					bottom: auto;
				}
				.keyimg04{
					top: 250px;
					right: -80px;
					left: auto;
					bottom: auto;
				}
				.keyimg05{
					top: 395px;
					right: 360px;
					left: auto;
					bottom: auto;
				}
				.keyimg06{
					top: 545px;
					right: 220px;
					left: auto;
					bottom: auto;
				}
				.keyimg07{
					top: 250px;
					// top: 50%;
					right: 70px;
					left: auto;
					bottom: auto;
				}
				.key-blurimg01{
					top: 220px;
					right: 266px;
					left: auto;
					bottom: auto;
				}
				.key-blurimg02{
					top: 400px;
					right: -10px;
					left: auto;
					bottom: auto;
				}
			}

			.slider02{
				.key-ceo-logo{
					position: absolute;
					bottom: 50px;
					left: 50%;
					-ms-transform: translate(-50%, 0);
					-webkit-transform: translate(-50%, 0);
					transform: translate(-50%, 0);
				}
				.slider-ttl{
					img{
						width: 800px;
						-webkit-filter: drop-shadow(1px 1px 3px #727272);
						-moz-filter: drop-shadow(1px 1px 3px #727272);
						filter: drop-shadow(1px 1px 3px #727272);
					}
				}
				.slider-ttl.sp-ttl{
					margin: 0;
					position: absolute;
					top: 50%;
					left: 30px;
					-ms-transform: translate(0, -30%);
					-webkit-transform: translate(0, -30%);
					transform: translate(0, -30%);
					-webkit-transition: all 0.2s ease;
					-moz-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					transition: all 0.2s ease;
					img.sp-keyttl{
						display: none;
					}
					img.pc-keyttl{
						display: block;
					}
				}
				.keyimg01{
					top: -30px;
					right: 10px;
					left: auto;
					bottom: auto;
				}
				.keyimg02{
					top: 114px;
					right: 300px;
					left: auto;
					bottom: auto;
				}
				.keyimg03{
					top: 260px;
					right: 150px;
					left: auto;
					bottom: auto;
				}
				.keyimg04{
					top: 276px;
					right: -6px;
					left: auto;
					bottom: auto;
				}
				.keyimg05{
					bottom: 30px;
					right: 295px;
					top: auto;
					left: auto;
				}
				.keyimg06{
					bottom: 10px;
					right: -142px;
					z-index: 1;
					top: auto;
					left: auto;
				}
				.keyimg07{
					bottom: 30px;
					right: 150px;
					top: auto;
					left: auto;
				}
				.key-blurimg01{
					top: 190px;
					right: -94px;
					left: auto;
					bottom: auto;
				}
				.key-blurimg02{
					top: 410px;
					right: 320px;
					left: auto;
					bottom: auto;
				}
			}
			.slider04{
				.key-ceo-logo{
					position: absolute;
					bottom: 50px;
					left: 50%;
					-ms-transform: translate(-50%, 0);
					-webkit-transform: translate(-50%, 0);
					transform: translate(-50%, 0);
				}
				.slider-ttl{
					z-index: 10;
					img{
						width: 800px;
						-webkit-filter: drop-shadow(1px 1px 3px #727272);
						-moz-filter: drop-shadow(1px 1px 3px #727272);
						filter: drop-shadow(1px 1px 3px #727272);
					}
				}
				.slider-ttl.sp-ttl{
					margin: 0;
					position: absolute;
					top: 50%;
					left: 30px;
					-ms-transform: translate(0, -30%);
					-webkit-transform: translate(0, -30%);
					transform: translate(0, -30%);
					-webkit-transition: all 0.2s ease;
					-moz-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					transition: all 0.2s ease;
					img.sp-keyttl{
						display: none;
					}
					img.pc-keyttl{
						display: block;
					}
				}
				.keyimg01{
					top: 60px;
					right: 10px;
					left: auto;
					bottom: auto;
				}
				.keyimg02{
					top: 164px;
					right: 260px;
					left: auto;
					bottom: auto;
				}
				.keyimg03{
					top: 210px;
					right: 10px;
					left: auto;
					bottom: auto;
				}
				.keyimg04{
					bottom: 230px;
					right: 310px;
					top: auto;
					left: auto;
				}
				.keyimg05{
					top: 375px;
					right: -120px;
					left: auto;
					bottom: auto;
					z-index: 1;
				}
				.keyimg06{
					bottom: 40px;
					right: 180px;
					top: auto;
					left: auto;
				}
				.keyimg07{
					top: 580px;
					right: 220px;
					left: auto;
					bottom: auto;
				}
				.key-blurimg01{
					top: 190px;
					right: -94px;
					left: auto;
					bottom: auto;
				}
				.key-blurimg02{
					top: 410px;
					right: 320px;
					left: auto;
					bottom: auto;
				}
			}

			.slider05{
				.key-ceo-logo{
					position: absolute;
					bottom: 50px;
					left: 50%;
					-ms-transform: translate(-50%, 0);
					-webkit-transform: translate(-50%, 0);
					transform: translate(-50%, 0);
				}
				.slider-ttl{
					-ms-transform: translate(0, -70%);
					-webkit-transform: translate(0, -70%);
					transform: translate(0, -70%);
					-webkit-filter: drop-shadow(1px 1px 3px #727272);
					-moz-filter: drop-shadow(1px 1px 3px #727272);
					filter: drop-shadow(1px 1px 3px #727272);
					z-index: 50;
					img{
						width: 700px;
					}
				}
				.slider-ttl.sp-ttl{
					margin: 0;
					position: absolute;
					top: 50%;
					left: 30px;
					-ms-transform: translate(0, -30%);
					-webkit-transform: translate(0, -30%);
					transform: translate(0, -30%);
					-webkit-transition: all 0.2s ease;
					-moz-transition: all 0.2s ease;
					-o-transition: all 0.2s ease;
					transition: all 0.2s ease;
					img.sp-keyttl{
						display: none;
					}
					img.pc-keyttl{
						display: block;
					}
				}
				.keyimg01{
					top: 0px;
					right: 65px;
					left: auto;
					bottom: auto;
					-webkit-transition: all .5s ease;
					-moz-transition: all .5s ease;
					-o-transition: all .5s ease;
					transition: all .5s ease;
				}
				.keyimg02{
					top: 143px;
					right: 356px;
					left: auto;
					bottom: auto;
					-webkit-transition: all .3s ease;
					-moz-transition: all .3s ease;
					-o-transition: all .3s ease;
					transition: all .3s ease;
				}
				.keyimg03{
					top: 287px;
					right: 211px;
					left: auto;
					bottom: auto;
					-webkit-transition: all 1s ease;
					-moz-transition: all 1s ease;
					-o-transition: all 1s ease;
					transition: all 1s ease;
					
				}
				.keyimg04{
					top: 290px;
					right: 70px;
					left: auto;
					bottom: auto;
					-webkit-transition: all .8s ease;
					-moz-transition: all .8s ease;
					-o-transition: all .8s ease;
					transition: all .8s ease;
				}
				.keyimg05{
					top: 434px;
					right: 360px;
					left: auto;
					bottom: auto;
					-webkit-transition: all 1.3s ease;
					-moz-transition: all 1.3s ease;
					-o-transition: all 1.3s ease;
					transition: all 1.3s ease;
				}
				.keyimg06{
					top: 433px;
					right: 213px;
					left: auto;
					bottom: auto;
					-webkit-transition: all 1.1s ease;
					-moz-transition: all 1.1s ease;
					-o-transition: all 1.1s ease;
					transition: all 1.1s ease;
				}
				.keyimg07{
					top: 433px;
					right: -78px;
					left: auto;
					bottom: auto;
					-webkit-transition: all 1.3s ease;
					-moz-transition: all 1.3s ease;
					-o-transition: all 1.3s ease;
					transition: all 1.3s ease;
				}
				.key-blurimg01{
					top: 140px;
					right: -34px;
					left: auto;
					bottom: auto;
				}
				.key-blurimg02{
					top: 310px;
					right: 390px;
					left: auto;
					bottom: auto;
				}
			}


		}
	}
	.fancybox-close-small{

	}
	.fancybox-button{
		color: #fff;
		// font-family: "Font Awesome 5 Free";
		// font-weight: 900;
		// -moz-osx-font-smoothing: grayscale;
		// -webkit-font-smoothing: antialiased;
		// display: inline-block;
		// font-style: normal;
		// font-variant: normal;
		// text-rendering: auto;
		// line-height: 1;
	}
	.fancybox-button:before {
		// content: "\f00d";
	}
}


@include media(sp){
	body#top{
		#page{
			#backto-top{
				opacity: 0;
			}
		}
		#page.show{
			#backto-top{
				opacity: 1;
			}
		}
	}
}