/*
file: _setting.scss
Description: 
Version: 2.2.0
Changelog: 【URL】
Author: NAME
License: 
*/


// ------------------------------------------------------------
// #命名規則
// BEMをベースに以下のルールで記載。
// block_element,_modifier
// ------------------------------------------------------------

// ------------------------------------------------------------
// #インデント
// インデントの種類はスペースを使用
// インデントのサイズはスペース2つ分
// ------------------------------------------------------------

// ------------------------------------------------------------
// #ブレークポイントの基本的な考え方
// スマートフォンファースト
// スマホ用CSSを先に記述し、メディアクエリ内でタブレットやPC用の記述をする
//
// ブレークポイント（$breakpoints）
// 基本的なブレークポイントとして、
// 768px（SPデザインとPCデザインの切り替え）を設定し、個別に
// ・769px以上960px未満
// ・960px以上
// を設定しています。
// ------------------------------------------------- メディアクエリ
// ブレークポイント一覧
//
//           769px     960px     1280px
//            ○          ○         ○
//      （sp） ｜ （tab）  ｜  (pc)  ｜
//   --------▶｜◀-------▶｜◀-------------
//            ｜         ｜
//            ｜（tab-sp）｜
//   -------------------▶｜
//            ｜
//            ｜（screen）
//            ｜◀------------------------


$breakpoints: (
  screen:"only screen and (min-width:769px)",
      pc:"only screen and (min-width:960px)",
     tab:"only screen and (min-width:769px) and (max-width:959px)",
  tab-sp:"only screen and (max-width:959px)",
      sp:"only screen and (max-width:768px)",
);
@mixin media($breakpoint){
  @media #{map-get($breakpoints,$breakpoint)}{
    @content;
  }
}

@include media(screen){
  .pcnone{ display: none; }
  a[href^="tel:"] {display: inline-block;
  // pointer-events: none;
  color: #000;}// 電話のリンクを無くす
}

@include media(sp){
  .spnone{ display: none; }
}



// ------------------------------------------------------------------------- 案件・個人の好みで変更可能

// z-indexの管理（プロジェクトごとに管理）
// @include z-index(hoge);で管理
$layer:(
  hamburger    :9000,
  sp-nav       :8900,
  header       :1000,
  top-catch    :100,
  top-visual   :50,
  contents     :0,
  visual       :-20,
  contents-obj :-10
);


$blue:#178dc7;// ビルガイアブルー
$gray:#f1f2f6;// ビルガイアグレー
$black:#2a2a2a;  // ビルガイアブラック
$red:#C68090;

$imgPath:'../img/';

// 游ゴシックだとIEで謎の余白が出るので、IEのみフォントを変える
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  body {
  // font-family: 'Noto Sans JP', "Hiragino Kaku Gothic","ヒラギノ角ゴ Pro W3","ＭＳ Ｐゴシック",Frutiger,Helvetica,sans-serif;
  /* 游ゴシックを使用する場合 */
  // font-family: "游ゴシック体", "YuGothic", "游ゴシック", "Yu Gothic", "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;

  font-family: "Noto Sans JP", "游ゴシック Medium", "游ゴシック体", "Yu Gothic Medium", YuGothic, "ヒラギノ角ゴ ProN", "Hiragino Kaku Gothic ProN", "メイリオ", Meiryo, "ＭＳ Ｐゴシック", "MS PGothic", sans-serif;
  font-weight: normal;
  /* 游ゴシックを使用しない場合 */
  // font-family: "ヒラギノ角ゴ Pro W3", "Hiragino Kaku Gothic Pro", "メイリオ", "Meiryo", sans-serif;
  // font-family: 'Modak', cursive;
  }
}
@mixin font-m { font-family: "秀英明朝 M","Shuei Mincho M","游明朝体","Yu Mincho",YuMincho,"ヒラギノ明朝 Pro","Hiragino Mincho Pro","MS P明朝","MS PMincho",serif; }

@mixin fonts-alfabet{
font-family: 'Lato', sans-serif;
}
// .font-m{font-family:"游明朝", YuMincho, "ヒラギノ明朝 ProN W3", "Hiragino Mincho ProN", "HG明朝E", "ＭＳ Ｐ明朝", "ＭＳ 明朝", serif;}

// Googleフォントを使用
  // .font-g{font-family: 'Roboto Condensed', sans-serif;}
  //明朝系 <link href="https://fonts.googleapis.com/earlyaccess/sawarabimincho.css" rel="stylesheet" />


// よく使うやつ
*{-webkit-tap-highlight-color:rgba(0,0,0,0);} /* スマホでラジオボタン・チェックボックスの背景を消す */

a, button{
  opacity:1;
  transition: all 0.2s linear;
  color: $black;
  text-decoration: none;
  &:hover{
    text-decoration: none;
    // opacity: 0.7;
  }
}
p{line-height: 1.5;font-size: 1.4rem;}
img{
  // width: 100%;
  object-fit: cover;}
// h1{font-family:"Sawarabi Mincho"}

.hov{&:hover{color: $red;text-decoration: underline;}}
.bar{
  border: none;
  border-bottom: 1px solid $gray;
  height: 1px;
  display: block;
}
.supp{font-size: 80%; margin-left: 0.2rem;}

*:focus {
  outline: none;
}

// ------------------------------------------------------------------------- SNS系
$wp:#21759b;//WordPress
$tw:#55acee;//twitter
$yt:#cd201f;//YouTube
$fb:#315096;//Facebook



// ------------------------------------------------------------------------- マージン系（Bootstrap）
@include media(screen){
  .col-md-12{width: 100%;}
  .col-md-11{width: 91.66666667%!important;}
  .col-md-10{width: 83.33333333%!important;}
  .col-md-9 {width: 75%!important;}
  .col-md-8 {width: 66.66666667%!important;}
  .col-md-7 {width: 58.33333333%!important;}
  .col-md-6 {width: 50%!important;}
  .col-md-5 {width: 41.66666667%!important;}
  .col-md-4 {width: 33.33333333%!important;}
  .col-md-3 {width: 25%!important;}
  .col-md-2 {width: 16.66666667%!important;}
  .col-md-1 {width: 8.33333333%!important;}
}
@include media(sp){
  .col-xs-12{width: 100%!important;}
  .col-xs-11{width: 91.66666667%!important;}
  .col-xs-10{width: 83.33333333%!important;}
  .col-xs-9 {width: 75%!important;}
  .col-xs-8 {width: 66.66666667%!important;}
  .col-xs-7 {width: 58.33333333%!important;}
  .col-xs-6 {width: 50%!important;}
  .col-xs-5 {width: 41.66666667%!important;}
  .col-xs-4 {width: 33.33333333%!important;}
  .col-xs-3 {width: 25%!important;}
  .col-xs-2 {width: 16.66666667%!important;}
  .col-xs-1 {width: 8.33333333%!important;}
}


// ------------------------------------------------------------------------- 属性セレクタ
// target="blank"の場合にブランクのアイコンが表示される。
a[target="blank"] {
  font-family: 'icomoon' !important;
  &:after{
    content: "\e901";
    margin-left: 0.5rem;
    font-size: 80%;
    margin-right: 0.5rem;
    text-decoration: none!important;
  }
}
// 画像には表示させたくないのでclass="imglink"を入れて非表示させないようにする
a.imglink[target="blank"]{
  &:after{
    content: "";
    margin: 0;
  }
}
