@charset "UTF-8";
@import "_setting";
@import "import/_var";
@import "top/_slider";
html,body{
	height: 100%;
}
body#top{
	
}

body#top{
	#page{
		min-width: 1280px;
	}
	@include media(sp){
		#page{
			min-width: auto;
		}
	}
	
	#promise{
		padding: 0 40px;
		.promise-ttl{
			color: #014099;
			font-weight: 500;
			text-align: center;
			font-size: 4.5rem;
			margin: 0 0 30px 0;
		}
		.promise-block{
			height: 100vh;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			position: relative;
			padding: 3% 0 0 0;
			.promise-block-ttl{
				@include flex(wrap,center,space-between);
				-ms-flex-align: center;
				align-items: center;
				margin: 0;
				color: #014099;
				font-size: 2rem;
				font-weight: 600;
				padding: 0 0 80px 0;
				position: relative;
				text-shadow: 2px 3px 20px rgba(255, 255, 255, 0.7), -2px 2px 20px rgba(255, 255, 255, 0.7), 2px -2px 20px rgba(255, 255, 255, 0.7), -2px -2px 20px rgba(255, 255, 255, 0.7);
				-moz-text-shadow: 2px 3px 20px rgba(255, 255, 255, 0.7), -2px 2px 20px rgba(255, 255, 255, 0.7), 2px -2px 20px rgba(255, 255, 255, 0.7), -2px -2px 20px rgba(255, 255, 255, 0.7);
				-webkit-text-shadow: 2px 3px 20px rgba(255, 255, 255, 0.7), -2px 2px 20px rgba(255, 255, 255, 0.7), 2px -2px 20px rgba(255, 255, 255, 0.7), -2px -2px 20px rgba(255, 255, 255, 0.7);
				span{
					// position:absolute;
					// top: 50%;
					// margin-top: -43px;
					// left: 10%;
					font-weight: 500;
					font-size: 8.6rem;
					padding-right: 10px;
				}
				.promise-ttl-txt{
					// letter-spacing: 8px;
					font-size: 4rem;
					line-height: 1.2;
				}
			}
			.promise-block-txt{
				text-align: center;
				position: absolute;
				bottom: 10%;
				left: 50%;
				-ms-transform: translate(-50%, 0);
				-webkit-transform: translate(-50%, 0);
				transform: translate(-50%, 0);
				background-color: rgba(1, 64, 153, 0.8);
				padding: 10px 20px;
				border-radius: 7px;
				p{
					margin: 0;
				}
				span{
					color: #fff;
					  // background-image: linear-gradient(45deg, #fcf69f, #ff8730);
					// background-color: #014099;
					// background-repeat: no-repeat;
					// background-position: bottom;
					// background-size: 100% 100%;0
					// padding: 4px 0 4px 0;
					// line-height: 2.3;
					line-height: 1.7;
					font-size: 2rem;
					// border-radius: 7px;
					// padding: 0 10px;
				}
			}
		}
	}
	@include media(sp){

	#promise{
		padding: 0;
		.promise-ttl{
			font-size: 3rem;
			padding: 0 10px;
		}
		.promise-block{
			height: 100vh;
			background-repeat: no-repeat;
			background-size: cover;
			background-position: center;
			position: relative;
			padding: 3% 0 0 0;
			.promise-block-ttl{
				@include flex(wrap,center,space-between);
				-ms-flex-align: center;
				align-items: center;
				margin: 0;
				color: #014099;
				font-size: 2rem;
				font-weight: 600;
				padding: 0 0 80px 0;
				position: relative;
				text-shadow: 2px 3px 20px rgba(255, 255, 255, 0.7), -2px 2px 20px rgba(255, 255, 255, 0.7), 2px -2px 20px rgba(255, 255, 255, 0.7), -2px -2px 20px rgba(255, 255, 255, 0.7);
				-moz-text-shadow: 2px 3px 20px rgba(255, 255, 255, 0.7), -2px 2px 20px rgba(255, 255, 255, 0.7), 2px -2px 20px rgba(255, 255, 255, 0.7), -2px -2px 20px rgba(255, 255, 255, 0.7);
				-webkit-text-shadow: 2px 3px 20px rgba(255, 255, 255, 0.7), -2px 2px 20px rgba(255, 255, 255, 0.7), 2px -2px 20px rgba(255, 255, 255, 0.7), -2px -2px 20px rgba(255, 255, 255, 0.7);
				span{
					// position:absolute;
					// top: 50%;
					// margin-top: -43px;
					// left: 10%;
					font-weight: 500;
					font-size: 6rem;
					padding-right: 10px;
				}
				.promise-ttl-txt{
					// letter-spacing: 8px;
					font-size: 2rem;
					line-height: 1.5;
				}
			}
			.promise-block-txt{
				width: 90%;
				p{
					margin: 0;
				}
				span{
					color: #fff;
					  // background-image: linear-gradient(45deg, #fcf69f, #ff8730);
					// background-color: #014099;
					// background-repeat: no-repeat;
					// background-position: bottom;
					// background-size: 100% 100%;
					padding: 4px 0 4px 0;
					line-height: 2;
					font-size: 1.8rem;
				}
			}
		}
	}
	}
	#topics{
		display: none;
		padding: 100px 0 110px 0;
		.topics-block{
				@include flex(wrap,center,space-between);
				-ms-flex-align: end;
				align-items: end;
			.topics-txt{
				width: 50%;
				@include flex(wrap,right,space-between);
				.topics-txt-wrap{
					width: 500px;
					padding: 0 90px 0 10px;
				}
				.topics-tag{
					color: #18384c;
					font-size: 1.4rem;
				}
				h2{
					font-size: 3rem;
					color: #014099;
					font-weight: 500;
					margin: 25px 0 50px 0;
				}
				p{
					color: #014099;
					line-height: 30px;
					margin: 0;
				}
				ul{
					list-style: none;
					padding: 0;
					margin: 40px 0 0 0;
					a,
					a:link,
					a:visited,
					a:active{
						color: #014099;
						display: block;
						padding: 20px 10px 20px 15px;
						border-bottom: 1px solid #014099;
						width: 240px;
						position: relative;
						.topics-more-icon{
							display: block;
							height: 1px;
							width: 20px;
							background: #d2a100;
							position: absolute;
							right: 10px;
							top: 50%;
							margin-top: -2px;
						}
						.topics-more-icon:before{
							content: "";
							width: 7px;
							height: 7px;
							display: block;
							background: #d2a100;
							border-radius: 5px;
							position: absolute;
							right: -7px;
							top: 50%;
							margin-top: -3px;
							@include trans;
						}
					}
					a:hover{
						@include hover;
						.topics-more-icon:before{
							right: 15px;
						}
					}
				}
			}
			.topics-img{
				width: 50%;
				img{
					width: 100%;
				}
			}
		}
	}
	@include media(sp){
		#topics{
			padding: 50px 0 110px 0;
			.topics-block{
					@include flex(wrap,center,space-between);
					-ms-flex-align: end;
					align-items: end;
					flex-direction: column-reverse;
				.topics-txt{
					width: 100%;
					@include flex(wrap,right,space-between);
					.topics-txt-wrap{
						width: 100%;
						padding: 20px 10px 0 10px;
					}
					.topics-tag{
						color: #18384c;
						font-size: 1.2rem;
					}
					h2{
						font-size: 2.8rem;
					}
					p{
					}
					ul{
						a,
						a:link,
						a:visited,
						a:active{
							width: 100%;
							position: relative;
							.topics-more-icon{
							}
							.topics-more-icon:before{
							}
						}
						a:hover{
							.topics-more-icon:before{
							}
						}
					}
				}
				.topics-img{
					width: 100%;
					img{
					}
				}
			}
		}
	}
	#product{
		background: #eaf1fc;
		margin: 40px 0;
		padding: 70px 0;
		#product-wrap{
			width: 1200px;
			margin: 0 auto;
			.product-ttl{
				color: #014099;
				font-size: 4.5rem;
				font-weight: 500;
				text-align: center;
				margin: 0;
			}
			.product-block{
				// @include flex;
				position: relative;
				.product-block-img{
					margin: 60px 0 0px -50px;
					img{
						filter: drop-shadow(-61px 35px 30px rgba(22, 72, 136, 0.25));
					}
				}
				.product-block-link{
					position: absolute;
					top: -20px;
					right: 70px;
					.product-block-ttl{
						font-size: 2.5rem;
						color: #18384c;
						font-weight: 400;
						margin: 0;
						.product-ttl-big{
							color: #19a8c2;
							font-size: 4rem;
							font-weight: 500;
							padding-left: 35px;
						}
						img{
							width: 80px;
						}
					}
					.product-block-sub-ttl{
						font-size: 1.8rem;
						margin: 0;
						color: #e2001e;
						padding: 20px 0 30px 120px;
					}
					.product-block-sub-ttl.base-system{
						color: #0450b8;
					}

					.product-link-list{
						list-style: none;
						padding: 0 0 0 120px;
						.product-link{
							margin-bottom: 18px;
							a,
							a:link,
							a:visited,
							a:active{
								font-size: 1.8rem;
								color: #18384c;
								font-weight: bold;
								position: relative;
								padding: 0 40px 0 40px;
								display: inline-block;
							}
							.number{
								position: absolute;
								top: 50%;
								left: 0;
								margin: -15px 0 0 0;
								background: #e2001e;
								display: block;
								height: 30px;
								width: 30px;
								border-radius: 30px;
								color: #fff;
								padding: 5px;
								span{
									@include center;
								}
							}
							.opner{
								position: absolute;
								top: 50%;
								right: 0;
								margin: -13px 0 0 0;
								display: block;
								height: 26px;
								width: 26px;
								border-radius: 20px;
								border: 2px solid #014099;
							}
							.opner:before{
								content: "";
								width: 2px;
								height: 15px;
								display: block;
								background: #014099;
								@include center;
								@include trans;
							}

							.opner:after{
								content: "";
								width: 15px;
								height: 2px;
								display: block;
								background: #014099;
								@include center;
								@include trans;
							}
							a:hover{
								@include hover;
								.opner:before{
									-ms-transform: translate(-50%, -50%) rotate( -deg );
									-webkit-transform: translate(-50%, -50%) rotate( -deg );
									transform: translate(-50%, -50%) rotate( -deg );
								}

								.opner:after{
								}
							}
						}
						.product-link:last-child{
							margin-bottom: 0;
						}
					}
					.product-link-list.base-system{
						.product-link{
							a,
							a:link,
							a:visited,
							a:active{
							}
							a:hover{
							}
							.number{
								background: #0450b8;
								span{
								}
							}
							.opner{
							}
							.opner:before{
							}

							.opner:after{
							}
						}
						.product-link:last-child{
						}
					}
				}
				.product-counter.product-block-link{
					.product-block-ttl{
						.product-ttl-big{
						}
						img{
						}
					}
					.product-block-sub-ttl{

					}

					.product-link-list{

					}
				}
			}
			.product-block.waiting-room{
				.product-block-img{
					position: relative;
					.pin{
						display: block;
						position: absolute;
						z-index: 10;
						width: 40px;
						height: 40px;
						border-radius: 40px;
						background: #e2001e;
						&:before{
							//赤い三角の部分
							position: absolute;
							bottom: -14px;
							border: solid transparent;
							border-top-color: #e2001e;
							border-width: 30px 20px 0 20px;
							content: ' ';
						}
						span{
							@include center;
							color: #fff;
							font-weight: bold;
						}
					}
					.online{
						top: 210px;
						left: 247px;
					}
					.sp-ticket{
						top: 189px;
						left: 406px;
					}
					.sp-tpay{
						top: 157px;
						left: 556px;
					}
					.autopay{
						top: 218px;
						left: 620px;
					}
					.machine{
						top: 374px;
						left: 324px;
					}
					.sp-reserve{

						top: 310px;
						left: 347px;

					}
					.popup{
						top: 331px;
						left: 274px;
					}
					.kalte{
						background: #0450b8;
						top: 267px;
						left: 230px;
						&:before{
							border-top-color: #0450b8;
						}
					}
					img{
					}
				}
				.product-block-img{
				}
				.product-block-link.product-counter{
					.product-block-ttl{
						.product-ttl-big{
						}
						img{
						}
					}
					.product-block-sub-ttl.counter{
					}
					.product-block-sub-ttl.base-system{
					}

					.product-link-list.clinic{
						.product-link{
							a,
							a:link,
							a:visited,
							a:active{
							}
							a:hover{
							}
							.number{
								span{
								}
							}
							.opner{
							}
							.opner:before{
							}

							.opner:after{
							}
						}
						.product-link:last-child{
						}
					}
					.product-link-list.base-system{
						.product-link{
							a,
							a:link,
							a:visited,
							a:active{
							}
							a:hover{
							}
							.number{
								span{
								}
							}
							.opner{
							}
							.opner:before{
							}

							.opner:after{
							}
						}
						.product-link:last-child{
						}
					}
				}
				.product-counter.product-block-link{
					.product-block-ttl{
						.product-ttl-big{
						}
						img{
						}
					}
					.product-block-sub-ttl{

					}

					.product-link-list{

					}
				}
			}
			.product-block.clinic{
				.product-block-img{
					margin: -30px 0 0px -50px;
					text-align: right;
					position: relative;
					.pin{
						display: block;
						position: absolute;
						z-index: 10;
						width: 40px;
						height: 40px;
						border-radius: 40px;
						background: #048bea;
						&:before{
							//赤い三角の部分
							position: absolute;
							bottom: -14px;
							left: 0;
							border: solid transparent;
							border-top-color: #048bea;
							border-width: 30px 20px 0 20px;
							content: ' ';
						}
						span{
							@include center;
							color: #fff;
							font-weight: bold;
						}
					}
					.test{
						top: 95px;
						right: 262px;
					}
					.x-ray{
						top: 152px;
						right: 380px;
					}
					.own-expense{
						top: 197px;
						right: 509px;
					}
					.sp-medic{
						top: 407px;
						right: 368px;
						background: #18b408;
						&:before{
							border-top-color: #18b408;
						}
					}
				}
				.product-block-link.clinic{
					position: absolute;
					top: -30px;
					right: auto;
					left: 60px;
					.product-block-ttl{
						.product-ttl-big{
							color: #19a8c2;
							padding-left: 35px;
						}
						img{
							width: 80px;
						}
					}
					.product-block-sub-ttl.clinic{
						margin: 0;
						padding: 13px 0 20px 120px;
						color: #048bea;
					}
					.product-block-sub-ttl.base-system{
						color: #18b408;
					}

					.product-link-list.clinic{
						list-style: none;
						padding: 0 0 0 120px;
						.product-link{
							margin-bottom: 18px;
							a,
							a:link,
							a:visited,
							a:active{
								color: #18384c;
								font-weight: bold;
								position: relative;
								padding: 0 40px 0 40px;
							}
							a:hover{
							}
							.number{
								background: #048bea;
								span{
									@include center;
								}
							}
							.opner{
								position: absolute;
								top: 50%;
								right: 0;
								margin: -13px 0 0 0;
								display: block;
								height: 26px;
								width: 26px;
								border-radius: 20px;
								border: 2px solid #014099;
							}
							.opner:before{
								content: "";
								width: 2px;
								height: 15px;
								display: block;
								background: #014099;
								@include center;
							}

							.opner:after{
								content: "";
								width: 15px;
								height: 2px;
								display: block;
								background: #014099;
								@include center;
							}
						}
						.product-link:last-child{
							margin-bottom: 0;
						}
					}
					.product-link-list.base-system{
						.product-link{
							a,
							a:link,
							a:visited,
							a:active{
							}
							a:hover{
							}
							.number{
								background: #18b408;
								span{
								}
							}
							.opner{
							}
							.opner:before{
							}

							.opner:after{
							}
						}
						.product-link:last-child{
						}
					}
				}
				.product-counter.product-block-link{
					.product-block-ttl{
						.product-ttl-big{
						}
						img{
						}
					}
					.product-block-sub-ttl{

					}

					.product-link-list{

					}
				}
			}
			.product-block.room{
				.product-block-img{
					position: relative;
					margin: -50px 0 0px 20px;
					.pin{
						display: block;
						position: absolute;
						z-index: 10;
						width: 40px;
						height: 40px;
						border-radius: 40px;
						background: #18b408;
						&:before{
							//赤い三角の部分
							position: absolute;
							bottom: -14px;
							left: 0;
							border: solid transparent;
							border-top-color: #18b408;
							border-width: 30px 20px 0 20px;
							content: ' ';
						}
						span{
							@include center;
							color: #fff;
							font-weight: bold;
						}
					}
					.online-shinryo{
						top: 153px;
						left: 86px;
					}
					.line{
						top: 295px;
						left: 340px;
					}
					.monshinhyo{
						top: 265px;
						left: 380px;
					}
					.shikakomi{
						top: 295px;
						left: 440px;
					}
					img{
					}
				}
				.product-block-link.room{
					top: 30px;
					right: 70px;
					.product-block-ttl{
						color: #18384c;
						.product-ttl-big{
						}
						img{
						}
					}
					.product-block-sub-ttl{
						margin: 0;
						color: #18b408;
					}
					.product-block-sub-ttl.base-system{
					}

					.product-link-list.room{
						list-style: none;
						padding: 0 0 0 120px;
						.product-link{
							margin-bottom: 18px;
							a,
							a:link,
							a:visited,
							a:active{
							}
							a:hover{
							}
							.number{
								background: #18b408;
								span{
								}
							}
							.opner{
								position: absolute;
								top: 50%;
								right: 0;
								margin: -13px 0 0 0;
								display: block;
								height: 26px;
								width: 26px;
								border-radius: 20px;
								border: 2px solid #014099;
							}
							.opner:before{
								content: "";
								width: 2px;
								height: 15px;
								display: block;
								background: #014099;
								@include center;
							}

							.opner:after{
								content: "";
								width: 15px;
								height: 2px;
								display: block;
								background: #014099;
								@include center;
							}
						}
						.product-link:last-child{
							margin-bottom: 0;
						}
					}
					.product-link-list.base-system{
						.product-link{
							a,
							a:link,
							a:visited,
							a:active{
							}
							a:hover{
							}
							.number{
								background: #0450b8;
								span{
								}
							}
							.opner{
							}
							.opner:before{
							}

							.opner:after{
							}
						}
						.product-link:last-child{
						}
					}
				}
				.product-counter.product-block-link{
					.product-block-ttl{
						.product-ttl-big{
						}
						img{
						}
					}
					.product-block-sub-ttl{

					}

					.product-link-list{

					}
				}
			}
		}
	}
	@include media(sp){
		#product{
			#product-wrap{
				width: auto;
				.product-ttl{
					font-size: 3rem;
				}
				.product-block{
					.product-block-img{
						width: 320px;
						margin: 0 auto;
						img{
						}
					}
					.product-block-link{
						position: static;
						padding: 0 10px;
						.product-block-ttl{
							position: relative;
							padding: 0 0 0 80px;
							.product-ttl-big{
								padding: 0;
							}
							img{
								position: absolute;
								top: 50%;
								left: 10px;
								margin-top: -40px;
								width: 50px;
							}
						}
						.product-block-sub-ttl{
							padding: 30px 10px 30px 10px;
						}
						.product-block-sub-ttl.base-system{
							padding: 30px 0 20px 0;
						}

						.product-link-list{
							padding: 0;
							.product-link{
								a,
								a:link,
								a:visited,
								a:active{
								}
								.number{
									span{
									}
								}
								.opner{
								}
								.opner:before{
								}

								.opner:after{
								}
								a:hover{
									@include hover;
									.opner:before{
									}

									.opner:after{
									}
								}
							}
							.product-link:last-child{
								margin-bottom: 0;
							}
						}
						.product-link-list.base-system{
							.product-link{
								a,
								a:link,
								a:visited,
								a:active{
								}
								a:hover{
								}
								.number{
									background: #0450b8;
									span{
									}
								}
								.opner{
								}
								.opner:before{
								}

								.opner:after{
								}
							}
							.product-link:last-child{
							}
						}
					}
					.product-counter.product-block-link{
						.product-block-ttl{
							.product-ttl-big{
							}
							img{
							}
						}
						.product-block-sub-ttl{

						}

						.product-link-list{

						}
					}
				}
				.product-block.waiting-room{
					.product-block-img{
						position: relative;
						margin: 100px auto 0 auto;
						.pin{
							width: 30px;
							height: 30px;
							&:before{
								bottom: -10px;
								border-width: 30px 15px 0 15px;
							}
							span{
							}
						}
						.online{
							top: 50px;
							left: 80px;
						}
						.sp-ticket{
							top: 40px;
							left: 160px;
						}
						.sp-tpay{
							top: 50px;
							left: auto;
							right: 70px;
						}
						.autopay{
							top: 90px;
							left: auto;
							right: 50px;
						}
						.machine{
							top: 140px;
							left: 140px;
						}
						.sp-reserve{
							top: 100px;
							left: 130px;
						}
						.popup{
							top: 120px;
							left: 100px;
						}
						.kalte{
							background: #0450b8;
							top: 90px;
							left: 60px;
							&:before{
								border-top-color: #0450b8;
							}
						}
						img{
						}
					}
					.product-block-link.product-counter{
						.product-block-ttl{
							.product-ttl-big{
							}
							img{
							}
						}
						.product-block-sub-ttl.counter{
							padding: 30px 0 20px 0;
						}
						.product-block-sub-ttl.base-system{
							padding: 30px 0 20px 0;
						}

						.product-link-list.clinic{
							.product-link{
								a,
								a:link,
								a:visited,
								a:active{
								}
								a:hover{
								}
								.number{
									span{
									}
								}
								.opner{
								}
								.opner:before{
								}

								.opner:after{
								}
							}
							.product-link:last-child{
							}
						}
						.product-link-list.base-system{
							.product-link{
								a,
								a:link,
								a:visited,
								a:active{
								}
								a:hover{
								}
								.number{
									span{
									}
								}
								.opner{
								}
								.opner:before{
								}

								.opner:after{
								}
							}
							.product-link:last-child{
							}
						}
					}
					.product-counter.product-block-link{
						.product-block-ttl{
							.product-ttl-big{
							}
							img{
							}
						}
						.product-block-sub-ttl{

						}

						.product-link-list{

						}
					}
				}
				.product-block.clinic{
					.product-block-img{
						margin: 100px auto 0 auto;
						text-align: right;
						position: relative;
						.pin{
							width: 30px;
							height: 30px;
							&:before{
								bottom: -10px;
								border-width: 30px 15px 0 15px;
							}
							span{
							}
						}
						.test{
							top: 30px;
							left: auto;
							right: 110px;
						}
						.x-ray{
							top: 50px;
							left: auto;
							right: 170px;
						}
						.own-expense{
							top: 70px;
							left: 60px;
							right: auto;
						}
						.sp-medic{
							top: auto;
							left: 130px;
							right: 0;
							background: #18b408;
							bottom: 110px;
							&:before{
								border-top-color: #18b408;
							}
						}
					}
					.product-block-link.clinic{
						position: static;
						.product-block-ttl{
							position: relative;
							padding: 0 0 0 100px;
							.product-ttl-big{
								padding: 0;
							}
							img{
								width: 80px;
								position: absolute;
								top: 50%;
								left: 10px;
								margin-top: -55px;
							}
						}
						.product-block-sub-ttl.clinic{
							margin: 0;
							padding: 30px 0 20px 0;
							color: #048bea;
						}
						.product-block-sub-ttl.base-system{
							padding: 30px 0 20px 0;
							color: #18b408;
						}

						.product-link-list.clinic{
							list-style: none;
							padding: 0 0 0 0;
							.product-link{
								margin-bottom: 18px;
								a,
								a:link,
								a:visited,
								a:active{
								}
								a:hover{
								}
								.number{
									span{
									}
								}
								.opner{
								}
								.opner:before{
								}

								.opner:after{
								}
							}
							.product-link:last-child{
								margin-bottom: 0;
							}
						}
						.product-link-list.base-system{
							padding: 0 0 0 0;
							.product-link{
								a,
								a:link,
								a:visited,
								a:active{
								}
								a:hover{
								}
								.number{
									background: #18b408;
									span{
									}
								}
								.opner{
								}
								.opner:before{
								}

								.opner:after{
								}
							}
							.product-link:last-child{
							}
						}
					}
					.product-counter.product-block-link{
						.product-block-ttl{
							.product-ttl-big{
							}
							img{
							}
						}
						.product-block-sub-ttl{

						}

						.product-link-list{

						}
					}
				}
				.product-block.room{
					.product-block-img{
						margin: 100px auto 0 auto;
						.pin{
							width: 30px;
							height: 30px;
							&:before{
								bottom: -10px;
								border-width: 30px 15px 0 15px;
							}
							span{
							}
						}
						.online-shinryo{
							top: 70px;
							left: 70px;
						}
						.line{
							top: auto;
							left: 140px;
							bottom: 120px;
						}
						.monshinhyo{
							top: auto;
							left: 170px;
							bottom: 140px;
						}
						.shikakomi{
							top: auto;
							left: 210px;
							bottom: 120px;
						}
						img{
						}
					}
					.product-block-link.room{
						top: 30px;
						right: 70px;
						.product-block-ttl{
							position: relative;
							padding: 0 0 0 80px;
							.product-ttl-big{
								padding: 0;
							}
							img{
								position: absolute;
								top: 50%;
								left: 10px;
								margin-top: -35px;
							}
						}
						.product-block-sub-ttl{
							margin: 0;
							color: #18b408;
						}
						.product-block-sub-ttl.base-system{
						}

						.product-link-list.room{
							list-style: none;
							padding: 0 0 0 120px;
							.product-link{
								margin-bottom: 18px;
								a,
								a:link,
								a:visited,
								a:active{
								}
								a:hover{
								}
								.number{
									background: #18b408;
									span{
									}
								}
								.opner{
									position: absolute;
									top: 50%;
									right: 0;
									margin: -13px 0 0 0;
									display: block;
									height: 26px;
									width: 26px;
									border-radius: 20px;
									border: 2px solid #014099;
								}
								.opner:before{
									content: "";
									width: 2px;
									height: 15px;
									display: block;
									background: #014099;
									@include center;
								}

								.opner:after{
									content: "";
									width: 15px;
									height: 2px;
									display: block;
									background: #014099;
									@include center;
								}
							}
							.product-link:last-child{
								margin-bottom: 0;
							}
						}
						.product-link-list.base-system{
							.product-link{
								a,
								a:link,
								a:visited,
								a:active{
								}
								a:hover{
								}
								.number{
									background: #0450b8;
									span{
									}
								}
								.opner{
								}
								.opner:before{
								}

								.opner:after{
								}
							}
							.product-link:last-child{
							}
						}
					}
					.product-counter.product-block-link{
						.product-block-ttl{
							.product-ttl-big{
							}
							img{
							}
						}
						.product-block-sub-ttl{

						}

						.product-link-list{

						}
					}
				}
			}
		}
	}
	.product-modal{
		display: none;
		width: 380px;
		padding: 0;
		img{
			width: 100%;
		}
		.modal-detail{
			padding: 30px 35px 80px 35px;
			h3{
				font-size: 1.8rem;
				color: #18384c;
				font-weight: bold;
				position: relative;
				padding: 0 40px 0 40px;
			}
			.number{
				position: absolute;
				top: 50%;
				left: 0;
				margin: -15px 0 0 0;
				background: #e2001e;
				display: block;
				height: 30px;
				width: 30px;
				border-radius: 30px;
				color: #fff;
				padding: 5px;
				span{
					@include center;
				}
			}
		}
		.scene-more{
			color: #014099;
			position: relative;
			padding: 0 50px 10px 0;
			border-bottom: 1px solid #014099;
			float: right;
		}
		.scene-more-icon{
			display: block;
			height: 1px;
			width: 20px;
			background: #d2a100;
			position: absolute;
			right: 10px;
			top: 50%;
			margin-top: -5px;
		}
		.scene-more-icon:before{
			content: "";
			width: 7px;
			height: 7px;
			display: block;
			background: #d2a100;
			border-radius: 5px;
			position: absolute;
			right: -7px;
			top: 50%;
			margin-top: -4px;
			@include trans;
		}
		.scene-more:hover{
			.scene-more-icon:before{
				right: 15px;
			}
		}
	}
	#result{
		text-align: center;
		padding-top: 50px;
		padding-bottom: 140px;
		h2{
			color: #014099;
			font-size: 4.5rem;
			font-weight: 600;
		}
		h3{
			color: #014099;
			font-size: 4.5rem;
			font-weight: 600;
		}
		.result-block{
			@include flex(wrap,center,space-between);
			-ms-flex-align: center;
			align-items: center;
			.result-detail{
				width: 280px;
				padding: 25px 0 20px 0;
				-moz-box-shadow: 0px 0px 15px 6px rgba(20, 133, 207, 0.1);
				-webkit-box-shadow: 0px 0px 15px 6px rgba(20, 133, 207, 0.1);
				-ms-box-shadow: 0px 0px 15px 6px rgba(20, 133, 207, 0.1);
				box-shadow: 0px 0px 15px 6px rgba(20, 133, 207, 0.1);
				margin-right: 10px;
				.result-txt{
					line-height: 2;
					margin: 0;
				}
				.result-txt-big{
					font-size: 4.5rem;
					font-weight: 600;
				}
			}
			.result-detail:last-child{
				margin-right: 0;
			}
		}
	}
	@include media(sp){
		#result{
			text-align: center;
			padding: 0 10px 70px 10px;
			h2{
				color: #014099;
				font-size: 2.4rem;
				font-weight: 600;
			}
			h3{
				color: #014099;
				font-size: 2rem;
				font-weight: 600;
			}
			.result-block{
				.result-detail{
					margin: 30px 0 0 0;
					.result-txt{
						line-height: 2.5;
						margin: 0;
					}
					.result-txt-big{
					}
				}
				.result-detail:last-child{
					margin-right: 0;
				}
			}
		}
	}

	#ir-news{
		width: 960px;
		margin: 30px auto 70px auto;
		padding: 65px 45px 50px 45px;
		border-radius: 20px;
		-moz-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-webkit-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		-ms-box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		box-shadow: 0px 0px 20px 10px rgba(0, 0, 0, 0.1);
		position: relative;
		.ir-more{
			position: absolute;
			top: 70px;
			right: 90px;
			font-size: 1.3rem;
			.drop-menu-icon{
				display: block;
				height: 1px;
				width: 20px;
				background: #d2a100;
				position: absolute;
				right: -30px;
				top: 50%;
				margin-top: 0px;
			}
			.drop-menu-icon:before{
				content: "";
				width: 6px;
				height: 6px;
				display: block;
				background: #d2a100;
				border-radius: 6px;
				position: absolute;
				right: -5px;
				top: 50%;
				margin-top: -3px;
				@include trans;
			}
		}
		.ir-more:hover{
			opacity: 0.8;
			filter: alpha(opacity=80);
			.drop-menu-icon{
			}
			.drop-menu-icon:before{
				right: 15px;
			}
		}
		.news-change{
			@include flex;
			h2{
				cursor: pointer;
				width: 50%;
				margin: 0;
				font-family: 'Asap',"Noto Sans JP";
				font-size: 2rem;
				padding-bottom: 16px;
				span{
					font-size: 1.3rem;
					padding-left: 20px;
				}
			}
			#news-btn{
				border-bottom: 2px solid #dddddd;
			}
			#ir-btn{
				border-bottom: 2px solid #dddddd;
			}
			#news-btn.news-active,
			#ir-btn.news-active{
				border-bottom: 2px solid #014099;
			}
		}
		.ir-news-block{
		}
		#news-block{
			dl{
				// @include flex(wrap,start,space-between);
				margin: 0;
				padding-top: 25px;
				padding-bottom: 25px;
				border-bottom: 1px solid #dddddd;
				dt{
					font-size: 1.4rem;
					color: #777777;
					font-weight: 400;
				}
				dd{
					margin: 0;
				}
				.label{
					color: #fff;
					margin: 0 0 0 10px;
					padding: 2px 14px;
					font-size: 1.3rem;
					background: #019d85;
				}
				.icon_legal{
					background: #019d85;
				}
				.icon_result{
					background: #e71648;
				}
				.icon_info{
					background: #e71648;
				}
				.txt{
					width: 100%;
					margin-top: 10px;
					position: relative;
				}
				.news-detail-txt{
					font-size: 1.3rem;
					color: #18384c;
					margin-top: 14px;
				}
				.icon_pdf:after{
					content: "PDF";
					display: block;
					position: absolute;
					right: -40px;
					top: 50%;
					font-size: 1rem;
					background: #014099;;
					padding: 0 3px;
					color: #fff;
					margin-top: -10px;
				}
				.label.icon_discro{background: #014099;}
				.label.icon_result{background: #e7174a;}
				.label.icon_legal{background: #009c84;}
				.label.icon_ir{background: #ee9200;}
				.label.icon_info{background: #33bde2;}
				a,
				a:link,
				a:visited,
				a:active{
					text-decoration: none;
					position: relative;
					color: #18384c;
					font-weight: 600;
				}
				a:hover{
				}
			}
		}
		.ir-btn{
			display: none;
		}
		#xj-mainlist{
			dl{
				@include flex(wrap,start,space-between);
				margin: 0;
				padding-top: 25px;
				dt{
					font-size: 1.4rem;
					color: #777777;
					font-weight: 400;
				}
				dd{
					margin: 0;
				}
				.label{
					color: #fff;
					margin: 0 0 0 10px;
					padding: 2px 14px;
					font-size: 1.3rem;
					background: #019d85;
				}
				.icon_legal{
					background: #019d85;
				}
				.icon_result{
					background: #e71648;
				}
				.icon_info{
					background: #e71648;
				}
				.txt{
					width: 100%;
					margin-top: 10px;
					padding-bottom: 25px;
					border-bottom: 1px solid #dddddd;
					position: relative;
				}
				.icon_pdf:after{
					content: "PDF";
					display: block;
					position: absolute;
					right: -40px;
					top: 50%;
					font-size: 1rem;
					background: #014099;;
					padding: 0 3px;
					color: #fff;
					margin-top: -10px;
				}
				.label.icon_discro{background: #014099;}
				.label.icon_result{background: #e7174a;}
				.label.icon_legal{background: #009c84;}
				.label.icon_ir{background: #ee9200;}
				.label.icon_info{background: #33bde2;}
				a,
				a:link,
				a:visited,
				a:active{
					text-decoration: none;
					position: relative;
					color: #18384c;
					font-weight: 600;
				}
				a:hover{
				}
			}
		}
	}
	@include media(sp){
		#ir-news{
			width: auto;
			margin: 50px 10px 70px 10px;
			padding: 50px 30px 40px 30px;
			.ir-more{
				top: 55px;
				right: 70px;
				.drop-menu-icon{
				}
				.drop-menu-icon:before{
				}
			}
			.ir-more:hover{
				.drop-menu-icon{
				}
				.drop-menu-icon:before{
				}
			}
			.ir-news-block{
				h2{
					span{
					}
				}
			}
			.news-change{
				h2{
					span{
						display: block;
						padding: 0;
					}
				}
				#news-btn{
				}
				#ir-btn{
				}
				#news-btn.news-active,
				#ir-btn.news-active{
				}
			}
			#xj-mainlist{
				dl{
					dt{
					}
					dd{
					}
					.label{
						font-size: 1.2rem;
						padding: 2px 10px;
					}
					.icon_legal{
					}
					.icon_result{
					}
					.icon_info{
					}
					.txt{
					}
					.icon_pdf:after{
						top: auto;
						bottom: 3px;
						right: -30px;
					}
					.label.icon_discro{background: #014099;}
					.label.icon_result{background: #e7174a;}
					.label.icon_legal{background: #009c84;}
					.label.icon_ir{background: #ee9200;}
					.label.icon_info{background: #33bde2;}
					a,
					a:link,
					a:visited,
					a:active{
					}
					a:hover{
					}
				}
			}
		}
	}



	#top-cta{
		height: 280px;
		background-color: #f2f5fa;
		background-position: right center;
		background-repeat: no-repeat;
		padding: 60px 0 40px 0;
		margin: 0 0 80px 0;
		.top-cta-wrap{
			width: 960px;
			margin: 0 auto;
			h3{
				font-size: 2.6rem;
				color: #18384c;
				margin: 0 0 20px 0;
			}
			p{
				font-size: 1.4rem;
				color: #18384c;
				margin: 0;
			}
			ul{
				list-style: none;
				margin: 0;
				padding: 30px 0 0 0;
				@include flex(wrap,left,space-between);
				li{
					.scene-more-icon{
						display: block;
						height: 1px;
						width: 20px;
						background: #d2a100;
						position: absolute;
						right: 15px;
						top: 50%;
						margin-top: -1px;
					}
					.scene-more-icon:before{
						content: "";
						width: 7px;
						height: 7px;
						display: block;
						background: #d2a100;
						border-radius: 5px;
						position: absolute;
						right: -7px;
						top: 50%;
						margin-top: -3px;
						@include trans;
					}
					a,
					a:link,
					a:visited,
					a:active{
						text-align: center;
						width: 240px;
						padding: 15px 10px;
						font-size: 1.4rem;
						position: relative;
					}
					a:hover{
						.scene-more-icon:before{
							right: 15px;
						}
					}
				}
				.dl-btn{
					a,
					a:link,
					a:visited,
					a:active{
						display: block;
						background: #014099;
						color: #fff;
						margin: 0 30px 0 0;
						border-bottom: 1px solid #014099;
					}
					a:hover{
						color: #014099;
						background: #fff;
					}
				}
				.btn-blue{
					
				}
				.btn-white{
					a,
					a:link,
					a:visited,
					a:active{
						display: block;
						color: #014099;
						background: #fff;
						border-bottom: 1px solid #014099;
					}
					a:hover{
						background: #014099;
						color: #fff;
					}
				}
			}
		}
	}
	@include media(sp){
		#top-cta{
			height: auto;
			padding: 300px 20px 40px 20px;
			background-position: right top;
			.top-cta-wrap{
				width: auto;
				h3{
					font-size: 2rem;
					text-align: center;
				}
				p{
					text-align: center;
				}
				ul{
					display: block;
					li{
						.scene-more-icon{
						}
						.scene-more-icon:before{
						}
						a,
						a:link,
						a:visited,
						a:active{
						}
						a:hover{
							.scene-more-icon:before{
							}
						}
					}
					.dl-btn{
						a,
						a:link,
						a:visited,
						a:active{
							width: 100%;
						}
						a:hover{
						}
					}
					.contact{
						margin-top: 30px;
						a,
						a:link,
						a:visited,
						a:active{
							width: 100%;
						}
						a:hover{
						}
					}
				}
			}
		}
	}
}


@include media(sp){

body#top{

}
}

@media screen and (max-width: 980px) {

}
